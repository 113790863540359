<template>
  <v-card-text class="pb-0 mt-6">
    <BlurredBackdrop
      :density="1"
      :is-blurred="!getFeature(featureNames.CMS).config.hasWidgets"
    >
      <template v-slot:info>
        <div>
          {{ $t('alerts.upselling.upgrade.0') }}
          <router-link
            to="/settings/contract"
            class="pointer white--text font-weight-bold"
          >
            {{ $t('alerts.upselling.upgrade.link') }}
          </router-link>
          {{ $t('alerts.upselling.upgrade.1') }}
        </div>
      </template>
      <v-row class="mb-2">
        <v-col
          cols="12"
          md="6"
        >
          <h3 class="mb-4">
            {{ $t('landingpages.snippet.code', { textCodeTitle: '' }) }}:
          </h3>

          <h4>{{ $t('landingpages.snippet.component.header') }}</h4>
          <CodeSnippet :snippet="srcSnippet" />
          <h4 class="mt-6">
            {{ $t('landingpages.snippet.component.body') }}
          </h4>
          <CodeSnippet :snippet="componentSnippet" />
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="12"
          md="6"
        >
          <h3 class="mb-4">
            {{ $t('labels.preview') }}:
          </h3>

          <div
            v-if="mounted"
            class="d-flex justify-center pa-6 grey"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="componentSnippet" />
          </div>
        </v-col>
      </v-row>
    </BlurredBackdrop>
  </v-card-text>
</template>

<script>
import GET_CMS_ORGANIZATION from './GetCmsOrganization.gql'
import CodeSnippet from './CodeSnippet.vue'
import feature from '@/mixins/feature'
import BlurredBackdrop from '@/components/BlurredBackdrop.vue'

export default {
  components: {
    CodeSnippet,
    BlurredBackdrop
  },
  mixins: [feature],
  props: {
    landingpageSlug: { type: String, default: null },
    readableLandingpage: { type: String, default: null },
    landingpageType: { type: String, default: '' }
  },
  data () {
    return {
      cmsOrganization: {},
      mounted: false
    }
  },
  computed: {
    component () {
      return `${this.cmsOrganization.widgetUrl}/components/${this.cmsOrganization.id}/${this.widget}/${this.cmsOrganization.defaultLocale}`
    },
    srcSnippet () {
      return `<script src="${this.component}" />`
    },
    componentSnippet () {
      return `<${this.widget} ${this.parameters} />`
    },
    widget () {
      return {
        CHECKLIST: 'btm-download',
        GUIDE: 'btm-download',
        RETURN_CALL: 'btm-return-call'
      }[this.landingpageType]
    },
    parameters () {
      return {
        CHECKLIST: `slug="${this.landingpageSlug}"`,
        GUIDE: `slug="${this.landingpageSlug}"`,
        RETURN_CALL: ''
      }[this.landingpageType]
    }
  },
  watch: {
    cmsOrganization () {
      if (!this.mounted) {
        const script = document.createElement('script')
        script.src = this.component
        script.async = true
        document.body.appendChild(script)
        this.mounted = true
      }
    }
  },
  apollo: {
    cmsOrganization: {
      query: GET_CMS_ORGANIZATION,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
